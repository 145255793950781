const banners: { src: string; link: string }[] = [
  {
    src: "/img/banners/dashly_BBA_banner.png",
    link: "https://smartmoneypeople.com/british-bank-awards/review/dashly-reviews/app?utm_user=dir",
  },
  {
    src: "/img/banners/dashly_msa_2024.png",
    link: "https://mortgagestrategyawards.co.uk/msaw24/en/page/industry-vote",
  },
  {
    src: "/img/banners/Dashly_Newsletter_Drama.png",
    link: "https://mortgagedoc.ai/",
  },
  {
    src: "/img/banners/SFI_voting.png",
    link: "https://sfiawards.co.uk/VOTING/",
  },
  {
    src: "/img/banners/small-step.png",
    link: "https://dashly.com/magazine/one-small-step-for-you-one-giant-leap-closer-to-being-consumer-duty-ready",
  },
  {
    src: "/img/banners/vote-for-us.jpg",
    link: "https://www.financialreporterawards.co.uk/vote",
  },
  {
    src: "/img/banners/protecting-mortgage-banner.png",
    link: "https://www.dashly.com/partners/mortgage-advisers",
  },
  {
    src: "/img/banners/protecting-mortgage-bannerV2.png",
    link: "https://www.dashly.com/partners/mortgage-advisers",
  },
  {
    src: "/img/banners/forward.png",
    link: "https://www.bcorporation.net/en-us/find-a-b-corp/company/dashly-ltd",
  },
];

export default banners;
