import React from "react";

const Generated = ({
  name,
  occupation,
  phone,
  email,
  bannerImg,
  bannerURL,
}: GeneratedProps) => {
  return (
    <div style={{ padding: 10 }}>
      <div style={{ padding: 0, margin: 0, fontSize: 0, width: 220 }}>
        <img
          src="/img/dashly.gif"
          alt="Dashly logo animated"
          width="240"
          height="52"
        />
      </div>
      <div>
        <a
          href="https://www.linkedin.com/company/dashly"
          target="_blank"
          rel="nofollow noreferrer"
          style={{
            fontFamily: "Arial, sans-serif",
            fontSize: 16,
            color: "#00457F",
            display: "inline-block",
            textDecoration: "underline",
            margin: "10px 0 0",
            lineHeight: 1.5,
            fontWeight: "bold",
          }}
        >
          {name}
        </a>
        <p
          style={{
            fontFamily: "Arial, sans-serif",
            fontSize: 12,
            color: "#97A8C0",
            margin: "2px 0 10px",
            fontWeight: 300,
            lineHeight: 1.5,
          }}
        >
          {occupation}
        </p>
      </div>

      <img
        src="/img/line.jpg"
        alt="signature line"
        width="40"
        height="1"
        style={{ display: "block", width: 40, height: 1 }}
      />
      <div>
        <p style={{ fontSize: 12, margin: "10px 0 0" }}>
          <span>
            <a
              style={{
                fontFamily: "Arial, sans-serif",
                fontSize: 12,
                color: "#00457F",
                textDecoration: "none",
                fontWeight: 300,
                lineHeight: 1.5,
              }}
              href={`tel:${phone}`}
            >
              {phone}
            </a>
          </span>
          <br />
          <span>
            <a
              style={{
                fontFamily: "Arial, sans-serif",
                fontSize: 12,
                color: "#00457F",
                textDecoration: "none",
                fontWeight: 300,
                lineHeight: 1.5,
              }}
              href={`mailto:${email}`}
            >
              {email}
            </a>
          </span>
        </p>
        <p style={{ margin: "18px 0 0" }}>
          <a
            href="https://dashly.com"
            target="_blank"
            rel="nofollow noreferrer"
            style={{
              fontFamily: "Arial, sans-serif",
              fontSize: 12,
              color: "#97A8C0",
              fontWeight: 300,
              textDecoration: "underline",
            }}
          >
            www.dashly.com
          </a>
        </p>
      </div>
      {bannerImg && bannerURL && bannerImg !== "" && bannerURL !== "" ? (
        <div style={{ marginTop: 10, width: 300 }}>
          <a
            href={bannerURL}
            style={{ width: 300 }}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <img src={bannerImg} alt="Dashly banner" style={{ width: 300 }} />
          </a>
        </div>
      ) : (
        <></>
      )}
      <div>
        <p
          style={{
            fontSize: 9,
            color: "darkgrey",
            fontStyle: "italic",
            margin: "10px 0 0",
          }}
        >
          <span>
            This email and the information it contains may be privileged and/or
            confidential. It is for the intended addressee(s) only. The
            unauthorised use, disclosure or copying of this email, or any
            information it contains is prohibited and could, in certain
            circumstances be a criminal offence. If you are not the intended
            recipient, please notify the original author immediately and delete
            the message from your system.
          </span>
          <br />
          <br />
          <span>
            Dashly Limited monitors emails to ensure its systems operate
            effectively and to minimise the risk of viruses. Whilst it has taken
            reasonable steps to scan this email, it does not accept liability
            for any virus that it may contain.
          </span>
          <br />
          <br />
          <span>
            Dashly Limited is a company registered in England and Wales under
            company number 09619788 and whose registered address is 22
            Charterhouse Square, London, EC1M 6DX.
          </span>
          <br />
          <br />
          <span>
            Dashly Limited is Authorised and regulated by the Financial Conduct
            Authority (Financial Services Register No. 810720).
          </span>
        </p>
      </div>
    </div>
  );
};

export default Generated;

type GeneratedProps = {
  name: string;
  occupation: string;
  phone: string;
  email: string;
  bannerImg?: string;
  bannerURL?: string;
};
