import React, { useState } from 'react';
import banners from '../banners';
import ImageSelect from './ImageSelect';
import '../styles/components/Form.scss';

const Form = ({ onGenerateClick, onCopyToClipboardClick }: FormProps) => {

    const [name, setName] = useState('');
    const [occupation, setOccupation] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [bannerImg, setBannerImg] = useState('');
    const [bannerURL, setBannerURL] = useState('');

    const isButtonDisabled = (): boolean => {
        let result = (name === '' || occupation === ''
            || phone === '' || email === '');

        return result;
    }

    const selectBanner = (id: number) => {
        if (id === -1) {
            setBannerImg('');
            setBannerURL('');
            return;
        }

        const src = banners[id].src;
        const link = banners[id].link;

        setBannerImg(src);
        setBannerURL(link);
    }

    const buttonDisabled = isButtonDisabled();

    return (
        <div className='form'>
            <label htmlFor='name'>Name</label>
            <input id='name' placeholder='Peter Harte' type='text'
                value={name} onChange={e => setName(e.target.value)} required={true} />

            <label htmlFor='occupation'>Occupation</label>
            <input id='occupation' placeholder='Senior Sales Development Manager' type='text'
                value={occupation} onChange={e => setOccupation(e.target.value)} required={true} />

            <label htmlFor='phone'>Phone</label>
            <input id='phone' placeholder='07595 911212' type='tel'
                value={phone} onChange={e => setPhone(e.target.value)} required={true} />

            <label htmlFor='email'>Email</label>
            <input id='email' placeholder='peter.harte@dashly.com' type='email'
                value={email} onChange={e => setEmail(e.target.value)} required={true} />

            <label htmlFor='banner-img'>Banner image (optional)</label>
            <div id='banner-img' className='banner-img'>
                <ImageSelect images={banners.map(banner => banner.src)}
                    value={bannerImg}
                    noSelectionLabel='No banner'
                    onValueChange={selectBanner} />
            </div>

            <div className='buttons'>
                <button disabled={buttonDisabled} className='button'
                    onClick={() => { onGenerateClick({ name, occupation, phone, email, bannerImg, bannerURL }) }}>
                    Generate
                </button>
                <label className='or'>
                    or
                </label>
                <button disabled={buttonDisabled} className='button'
                    onClick={() => { onCopyToClipboardClick({ name, occupation, phone, email, bannerImg, bannerURL }) }}>
                    Copy to clipboard
                </button>
            </div>
        </div>
    )
}

export default Form;

type FormProps = {
    onGenerateClick: (data: FormData) => void;
    onCopyToClipboardClick: (data: FormData) => void;
}

export type FormData = {
    name: string;
    occupation: string;
    phone: string;
    email: string;
    bannerImg: string;
    bannerURL: string;
}
